<template>
  <section>
    <div class="content-header">
      <h2>Leading with curiosity: Learning about identities you don’t hold </h2>
    </div>
    <div class="content-wrapper">
      <p>This section of the toolkit features opportunities to learn about identities you might not hold. These are excerpts among many rich resources and student testimonials in Ivey Business School’s Equity, Diversity and Inclusion module for incoming students in the Honours Business Administration program. The module has been used with permission from Nadine de Gannes.</p>
      <p>This comprehensive and impressive module weaves student experiences throughout and offers opportunities to learn about equity, diversity and inclusion in so many ways:</p>
      <ul>
        <li class="pb-2">By describing in detail various identities students might hold, with corresponding notes about language, cultural considerations, myths, stereotypes and nuances</li>
        <li class="pb-2">By featuring stories from actual students about how sexuality, race and other identities have impacted their lives </li>
        <li>By highlighting empowering stories of public figures who hold different marginalized identities</li>
      </ul>
      <p>Take the time to read through the identity pillars in this section of the toolkit and take note of any new terminology or surprising insights. You can draw from this resource to <strong>share information that will help your students reflect on their own identities within the classroom community, and build a baseline of information and curiosity about different identities that you don’t hold</strong>.</p>
      <p>Ways to use this learning material:</p>
      <div class="carousel">
        <hooper
          ref="carousel_01"
          class="mb-4"
          :wheelControl="false"
        >
          <slide>
            <div class="slide">
              <p>Have cohorts of students at an institutional, program or individual course level review this content to build a baseline of understanding related to identities they may not hold</p>
            </div>
          </slide>

          <slide>
            <div class="slide">
              <p>Refer to specific identity content when sharing class content referencing an identity group to build understanding within your course</p>
            </div>
          </slide>

          <slide>
            <div class="slide">
              <p>Review the information yourself, and among your faculty peers, to increase your understanding of specific identity groups before engaging in class discussions about equity, diversity and inclusion (EDI) topics</p>
            </div>
          </slide>

          <hooper-navigation slot="hooper-addons">
            <img
              slot="hooper-prev"
              src="@/assets/img/_ui/svg/carousel-back.svg"
              alt="previous"
              srcset=""
            >
            <img
              slot="hooper-next"
              src="@/assets/img/_ui/svg/carousel-next.svg"
              alt="next"
              srcset=""
            >
          </hooper-navigation>

          <hooper-pagination slot="hooper-addons"></hooper-pagination>
        </hooper>
      </div>
    </div>
  </section>
</template>

<script>
// import Accordion from '@/components/Accordion.vue'
// import SidebarMenu from '@/components/SidebarMenu.vue'
import {
  Hooper,
  Slide,
  Navigation as HooperNavigation,
  Pagination as HooperPagination
} from 'hooper'
import 'hooper/dist/hooper.css'

export default {
  name: 'content',
  components: {
    // SidebarMenu,
    // Accordion,
    Hooper,
    Slide,
    HooperNavigation,
    HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
